/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    function Utils() {

    }

    Utils.prototype = {
        constructor: Utils,
        isElementInView: function (element, fullyInView) {

            var pageTop = $(window).scrollTop();
            var pageBottom = pageTop + $(window).height();
            var elementTop = $(element).offset().top;
            var elementBottom = elementTop + $(element).height();

            if (fullyInView === true) {
                return ((pageTop < elementTop) && (pageBottom > elementBottom));
            } else {
                return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
            }
        }
    };

    var DEALERLOCATOR = DEALERLOCATOR || function() {
            var map;
            var markers = [];
            var infoWindow;
            var searchBox;
            var locationSelect;
            var radius;
            return {
                initMap: function () {
                    /* var sydney = {lat: MAP.lat, lng: MAP.lng};

                    map = new google.maps.Map(document.getElementById('map'), {
                        center: sydney,
                        zoom: 11,
                        mapTypeId: 'roadmap',
                        mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU}

                    });*/



                    $('#addressInput').val(MAP.city);
                    var cLocation =  {lat: Number(MAP.lat), lng: Number(MAP.lng)};
                    if(cLocation.lat == 0){
                        cLocation = {lat: 45.462889, lng: 9.0376537};
                    }
                    map = new google.maps.Map(document.getElementById('map'), {
                        center: cLocation,
                        zoom: 6,
                        mapTypeId: 'roadmap',
                        mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
                        disableDefaultUI: true,
                        styles:
                            [
                                {
                                    "featureType": "all",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "weight": "2.00"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "all",
                                    "elementType": "geometry.stroke",
                                    "stylers": [
                                        {
                                            "color": "#9c9c9c"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "all",
                                    "elementType": "labels.text",
                                    "stylers": [
                                        {
                                            "visibility": "on"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "landscape",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "color": "#f2f2f2"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "landscape",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "color": "#ffffff"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "landscape.man_made",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "color": "#ffffff"
                                        },
                                        {
                                            "visibility": "on"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "landscape.natural.landcover",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "visibility": "on"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "poi",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "poi.park",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "visibility": "on"
                                        },
                                        {
                                            "lightness": "58"
                                        },
                                        {
                                            "saturation": "-49"
                                        },
                                        {
                                            "gamma": "1.38"
                                        },
                                        {
                                            "weight": "1.91"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "saturation": -100
                                        },
                                        {
                                            "lightness": 45
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "color": "#eeeeee"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road",
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                        {
                                            "color": "#7b7b7b"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road",
                                    "elementType": "labels.text.stroke",
                                    "stylers": [
                                        {
                                            "color": "#ffffff"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.highway",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "visibility": "simplified"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.arterial",
                                    "elementType": "labels.icon",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "transit",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "water",
                                    "elementType": "all",
                                    "stylers": [
                                        {
                                            "color": "#46bcec"
                                        },
                                        {
                                            "visibility": "on"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "water",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "color": "#c8d7d4"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "water",
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                        {
                                            "color": "#070707"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "water",
                                    "elementType": "labels.text.stroke",
                                    "stylers": [
                                        {
                                            "color": "#ffffff"
                                        }
                                    ]
                                }
                            ]
                    });
                    google.maps.event.addListener(map, 'idle', function(event) {

                        /** Converts numeric degrees to radians */
                        if (typeof(Number.prototype.toRad) === "undefined") {
                            Number.prototype.toRad = function() {
                                return this * Math.PI / 180;
                            }
                        }
                        var bounds = map.getBounds();
                        var northEast = bounds.getNorthEast();
                        var southWest = bounds.getSouthWest();
                        var lat1 = northEast.lat();
                        var lat2 = southWest.lat();
                        var lon1 = northEast.lng();
                        var lon2 = southWest.lng();


                        var R = 6371000; // metres
                        var φ1 = lat1.toRad();
                        var φ2 = lat2.toRad();
                        var Δφ = (lat2-lat1).toRad();
                        var Δλ = (lon2-lon1).toRad();

                        var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                            Math.cos(φ1) * Math.cos(φ2) *
                            Math.sin(Δλ/2) * Math.sin(Δλ/2);
                        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
                        var d = R * c;
                        DEALERLOCATOR.radius = (d / 1000);
                        //DEALERLOCATOR.searchLocations();

                    });
                   // searchButton = document.getElementById("searchButton").onclick = DEALERLOCATOR.changeCenter;
                    // Create the search box and link it to the UI element.
                    var input = document.getElementById('addressInput');
                    var searchBox = new google.maps.places.SearchBox(input);
                   // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

                    // Bias the SearchBox results towards current map's viewport.
                    /*map.addListener('bounds_changed', function() {
                        searchBox.setBounds(map.getBounds());
                    });*/
                    map.addListener('bounds_changed', function() {
                        searchBox.setBounds(map.getBounds());
                    });
                    searchBox.addListener('places_changed', function() {
                        var places = searchBox.getPlaces();

                        if (places.length == 0) {
                            return;
                        }

                        // Clear out the old markers.

                        markers = [];

                        // For each place, get the icon, name and location.
                        var bounds = new google.maps.LatLngBounds();
                        places.forEach(function(place) {
                            if (!place.geometry) {
                                console.log("Returned place contains no geometry");
                                return;
                            }
                            var icon = {
                                url: place.icon,
                                size: new google.maps.Size(71, 71),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(17, 34),
                                scaledSize: new google.maps.Size(25, 25)
                            };



                            if (place.geometry.viewport) {
                                // Only geocodes have viewport.
                                bounds.union(place.geometry.viewport);
                            } else {
                                bounds.extend(place.geometry.location);
                            }
                        });
                        map.fitBounds(bounds);

                       // $('#searchButton').click();
                    });

                    /*map.addListener('dragend', function() {
                        $("#dealers").css('opacity', '0.2');
                        setTimeout(function() {
                            var c = map.getCenter();
                            DEALERLOCATOR.searchLocationsNear(c, true);

                        },500);
                    });*/

                    infoWindow = new google.maps.InfoWindow();
                    if(MAP.city == '') {
                        // Try HTML5 geolocation.
                        if (navigator.geolocation) {
                            navigator.geolocation.getCurrentPosition(function (position) {
                                var pos = {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude
                                };

                                infoWindow.setPosition(pos);
                                DEALERLOCATOR.searchLocationsNear(pos);
                                // infoWindow.open(map);
                                map.setCenter(pos);
                            }, function () {
                                handleLocationError(true, infoWindow, map.getCenter());
                            });
                        } else {
                            // Browser doesn't support Geolocation
                            handleLocationError(false, infoWindow, map.getCenter());
                        }
                    }else{
                        var pos =  {lat: Number(MAP.lat), lng: Number(MAP.lng)};
                        DEALERLOCATOR.searchLocationsNear(pos, false);
                    }




                    /*
                    google.maps.event.addDomListener( map, 'click', function(e) {
                       console.log(google.maps.getCenter());
                        //google.maps.event.trigger(map,'resize');
                       // map.setZoom(map.getZoom());
                    });
                    */

                    /*locationSelect = document.getElementById("locationSelect");
                    locationSelect.onchange = function () {
                        var markerNum = locationSelect.options[locationSelect.selectedIndex].value;
                        if (markerNum != "none") {
                            google.maps.event.trigger(markers[markerNum], 'click');
                        }
                    };*/
                },
                changeCenter: function () {

                    searchBox.setBounds(map.getBounds());
                },
                searchLocations: function () {
                    var address = document.getElementById("addressInput").value;
                    var geocoder = new google.maps.Geocoder();
                    geocoder.geocode({address: address}, function (results, status) {
                        if (status == google.maps.GeocoderStatus.OK) {

                            DEALERLOCATOR.searchLocationsNear(results[0].geometry.location, false);

                        } else {
                            alert(address + ' not found');
                        }

                    });
                },

                clearLocations: function () {
                    infoWindow.close();
                    for (var i = 0; i < markers.length; i++) {
                        markers[i].setMap(null);
                    }
                    markers.length = 0;

                   // locationSelect.innerHTML = "";
                    var option = document.createElement("option");
                    option.value = "none";
                    option.innerHTML = "See all results:";
                  //  locationSelect.appendChild(option);
                },

                searchLocationsNear: function (center, textyonly) {
                    var strTextyonly = (textyonly) ? 1 : 0;
                    if(!textyonly) {
                        DEALERLOCATOR.clearLocations();
                    }
                    try {
                        var lat = center.lat();
                        var lng = center.lng();
                    }catch(err){
                        lat = center.lat;
                        lng = center.lng;
                    }

                    var radius = (DEALERLOCATOR.radius != 0) ? DEALERLOCATOR.radius : 300;
                    var searchUrl = LM.templateDir + '/tools/dealers.xml';//xml.php?lat=' + lat + '&lng=' + lng + '&radius=' + 150 + '&textyonly=' + strTextyonly;
                    this.downloadUrl(searchUrl, function (data) {
                        var xml = DEALERLOCATOR.parseXml(data);
                        var markerNodes = xml.documentElement.getElementsByTagName("marker");
                        $('#dealers').hide();
                        $('#dealers_list').html('');
                        for (var i = 0; i < markerNodes.length; i++) {
                            var id = markerNodes[i].getAttribute("id");
                            var name = markerNodes[i].getAttribute("name");
                            var address = markerNodes[i].getAttribute("address");
                            var distance = parseFloat(markerNodes[i].getAttribute("distance"));


                            var postalcode = markerNodes[i].getAttribute("postalcode");
                            var city = markerNodes[i].getAttribute("city");
                            var state = markerNodes[i].getAttribute("state");
                            var country = markerNodes[i].getAttribute("country");
                            var telephonnumber = markerNodes[i].getAttribute("telephonnumber");
                            var fax = markerNodes[i].getAttribute("fax");
                            var website = markerNodes[i].getAttribute("website");
                            var category = markerNodes[i].getAttribute("category");
                            var info = {
                                name: name,
                                address: address,
                                postalcode: postalcode,
                                city: city,
                                country: LAMBRETTA.capitalizeFirstLetter(country),
                                state: state,
                                telephonnumber: telephonnumber,
                                fax: fax,
                                website: website,
                                category: category
                            };

                          //  DEALERLOCATOR.createInfo(info,i);

                            //DEALERLOCATOR.createOption(name, distance, i);
                            if(!textyonly) {
                                var latlng = new google.maps.LatLng(
                                    parseFloat(markerNodes[i].getAttribute("lat")),
                                    parseFloat(markerNodes[i].getAttribute("lng")));
                                var bounds = new google.maps.LatLngBounds();
                                DEALERLOCATOR.createMarker(latlng, info);
                                bounds.extend(latlng);
                            }
                            $("#dealers").css('opacity', '1');
                        }


//                        map.fitBounds(bounds);
                       /* locationSelect.style.visibility = "visible";
                        locationSelect.onchange = function () {
                            var markerNum = locationSelect.options[locationSelect.selectedIndex].value;
                            google.maps.event.trigger(markers[markerNum], 'click');
                        };*/
                    });
                },
                handleDrag:function () {
                    map.addListener('center_changed', function() {
                        var c = map.getCenter();
                        //DEALERLOCATOR.searchLocationsNear(c, false);
                    });
                },
                createMarker: function (latlng, info) {


                    var p = jQuery('<span/>');

                    if(info.category == 2) {
                        p.append($('<h3/>').html(info.name));
                        p.append($('<i/>').html(LM.Importer));
                        p.append($('<br/>'));
                    }else {
                        p.append($('<h3/>').html(info.name))
                    }
                    if(info.category <= 2) {

                        DEALERLOCATOR.checkIfFilled(p, info.address, '');
                        DEALERLOCATOR.checkIfFilled(p, info.postalcode + ', ' + info.city, '<br/>');
                        DEALERLOCATOR.checkIfFilled(p, info.country, '<br/>');
                        DEALERLOCATOR.checkIfFilled(p, info.telephonnumber, '<br/>' + LM.strTEL + ': ');
                        if(info.website != '-' & info.website != '') {
                            p.append('<br/>');
                            p.append($('<a/>').attr('href', info.website).html(info.website));
                        }
                        var htmlLink =
                            $('<a/>', {
                                'href' : "https://www.google.com/maps/dir/?api=1&destination=" + info.address + "&name=" + info.name,
                                'target' : '_blank',
                                'html' : LM.strGetDirections
                            });
                        p.append('<br/><br/>');
                        p.append(htmlLink);



                        }else{
                            var htmlLink =
                                $('<a/>', {
                                    'href' : "mailto:info@lambretta.com?SUBJECT=Comming+soon: " + info.country,
                                    'target' : '_blank',
                                    'html' : 'info@lambretta.com'
                                });
                            p.append('<br/>' + LM.strCommingSoon + '<br/>' +
                                LM.strMoreInfo + ': <br/> ');
                            p.append(htmlLink);
                    };



                    var html = p.html();

                    var icon = (info.category == 2) ? LM.mapimporticon : LM.mapicon;
                    icon = (info.category == 3) ? LM.commingSoonicon : icon;
                    var marker = new google.maps.Marker({
                        map: map,
                        position: latlng,
                        icon:icon
                    });

                    google.maps.event.addListener(marker, 'click', function () {
                       // infoWindow.setContent(html);
                       // infoWindow.open(map, marker);
                        $('#dealers').show();
                        $('#dealers_list').html(html);
                    });
                    markers.push(marker);
                },
                createInfo: function (info, num) {
                    var htmlLink =
                        $('<a/>', {
                            'href' : "https://www.google.com/maps/dir/?api=1&destination=" + info.address + "&name=" + info.name,
                            'target' : '_blank',
                            'html' : LM.strGetDirections
                        });
                    var li = jQuery('<li/>');
                    var p = jQuery('<p/>');


                    if(info.category == 2) {
                        p.append($('<h3/>', {
                            html: info.name,
                            'class' : 'importer'
                            })
                        );
                        p.append($('<h4/>').html(LM.Importer));
                    }else{
                        p.append($('<h3/>').html(info.name));
                    }

                    if(info.category <= 2) {
                        DEALERLOCATOR.checkIfFilled(p, info.address);
                        DEALERLOCATOR.checkIfFilled(p, info.postalcode + ', ' + info.city);
                        DEALERLOCATOR.checkIfFilled(p, info.country);
                        DEALERLOCATOR.checkIfFilled(p, info.telephonnumber, LM.strTEL + ': ');
                        DEALERLOCATOR.checkIfFilled(p, info.fax, LM.strFAX + ': ');
                        if (info.website != '-' & info.website != '') {

                            p.append($('<a/>').attr('href', info.website).html(info.website));
                        }
                        p.append($('<br/>'));
                        p.append(htmlLink);

                    }else{

                        var htmlLink =
                            $('<a/>', {
                                'href' : "mailto:info@lambretta.com?SUBJECT=Comming+soon: " + info.country,
                                'target' : '_blank',
                                'html' : 'info@lambretta.com'
                            });
                        p.append('<br/>' + LM.strCommingSoon + ' <br/>' +
                           LM.strMoreInfo +': <br/> ');
                        p.append(htmlLink);

                    }


                    p.on('hover', function () {
                        var markerNum = num;
                        if (markerNum != "none") {
                            google.maps.event.trigger(markers[markerNum], 'click');
                        }
                    });

                    li.append(p);
                    li.on('click', function () {
                        $("#navigation").prop('checked', false);
                    });
                    jQuery('#dealers').hide();//append(li);
                },
                checkIfFilled: function (div, value, prevalue) {
                    if(typeof prevalue === 'undefined'){
                        prevalue = '';
                    }
                    if(value != '-' && value != '') {
                        div.append($('<span/>').html(prevalue + value));
                    }
                },
                createOption: function (name, distance, num) {
                    var option = document.createElement("option");
                    option.value = num;
                    option.innerHTML = name;
                  //  locationSelect.appendChild(option);
                },
                downloadUrl: function (url, callback) {
                    var request = window.ActiveXObject ?
                        new ActiveXObject('Microsoft.XMLHTTP') :
                        new XMLHttpRequest;

                    request.onreadystatechange = function () {
                        if (request.readyState == 4) {
                            request.onreadystatechange = DEALERLOCATOR.doNothing;
                            callback(request.responseText, request.status);
                        }
                    };

                    request.open('GET', url, true);
                    request.send(null);
                },
                parseXml: function (str) {
                    if (window.ActiveXObject) {
                        var doc = new ActiveXObject('Microsoft.XMLDOM');
                        doc.loadXML(str);
                        return doc;
                    } else if (window.DOMParser) {
                        return (new DOMParser).parseFromString(str, 'text/xml');
                    }
                },
                doNothing: function () {
                }

            }
        }();

    var Utils = new Utils();
    var LAMBRETTA = LAMBRETTA || function(){
            return {
                    setParral: function(){
                    $(".parral-div-holder").each(function() {

                        var isElementInView = Utils.isElementInView($(this), false);

                        if (isElementInView) {
                          //  console.log('in view');
                            $(this).find('.setparral').addClass('parral-div');
                         //   $(this).height($(this).next('figure'));
                        } else {
                            $(this).find('.setparral').removeClass('parral-div');
                       //     $(this).height('100%');
                        }

                    });
                },
                setGallery: function () {


                        /*global window, jQuery, Galleria */

                        Galleria.addTheme({
                            name: 'lambretta',
                            version: 1.6,
                            author: 'Bekcomp',

                            defaults: {
                                transition: 'fade',
                                thumbCrop:  'height',

                                // set this to false if you want to show the caption all the time:
                                _toggleInfo: false
                            },
                            init: function(options) {

                                // add some elements
                              /*  this.addElement('info-link','info-close');
                                this.append({
                                    'info' : ['info-link','info-close']
                                });*/

                                // cache some stuff
                                var info = this.$('info-link,info-close,info-text'),
                                    touch = Galleria.TOUCH;

                                // show loader & counter with opacity
                                this.$('loader,counter').show().css('opacity', 0.4);

                                // some stuff for non-touch browsers
                                if (! touch ) {
                                    this.addIdleState( this.get('image-nav-left'), { left:-50 });
                                    this.addIdleState( this.get('image-nav-right'), { right:-50 });
                                    this.addIdleState( this.get('counter'), { opacity:0 });
                                }

                                // toggle info
                                if ( options._toggleInfo === true ) {
                                    info.bind( 'click:fast', function() {
                                        info.toggle();
                                    });
                                } else {
                                    info.show();
                                    this.$('info-link, info-close').hide();
                                }

                                // bind some stuff
                                this.bind('thumbnail', function(e) {

                                    if (! touch ) {
                                        // fade thumbnails
                                        $(e.thumbTarget).css('opacity', 0.6).parent().hover(function() {
                                            $(this).not('.active').children().stop().fadeTo(100, 1);
                                        }, function() {
                                            $(this).not('.active').children().stop().fadeTo(400, 0.6);
                                        });

                                        if ( e.index === this.getIndex() ) {
                                            $(e.thumbTarget).css('opacity',1);
                                        }
                                    } else {
                                        $(e.thumbTarget).css('opacity', this.getIndex() ? 1 : 0.6).bind('click:fast', function() {
                                            $(this).css( 'opacity', 1 ).parent().siblings().children().css('opacity', 0.6);
                                        });
                                    }
                                });

                                var activate = function(e) {
                                    $(e.thumbTarget).css('opacity',1).parent().siblings().children().css('opacity', 0.6);
                                };

                                this.bind('loadstart', function(e) {
                                    if (!e.cached) {
                                        this.$('loader').show().fadeTo(200, 0.4);
                                    }
                                    window.setTimeout(function() {
                                        activate(e);
                                    }, touch ? 300 : 0);
                                    this.$('info').toggle( this.hasInfo() );
                                });

                                this.bind('loadfinish', function(e) {
                                    this.$('loader').fadeOut(200);
                                });
                            }
                        });


                    Galleria.run('.firstgallery');

                    $('.changeGallaria').on('click', function () {
                        $('.sectie-fcV_special_album').find('.active').removeClass('active');
                        $(this).addClass('active');
                        var gClass = '.' +  $(this).attr('id');
                        jQuery('.galleria').addClass('hidden');

                        Galleria.run(gClass);
                        jQuery(gClass).removeClass('hidden');
                    });


                },
                handleVideo: function (video) {
                    'use strict';

                    // Does the browser actually support the video element?
                    var supportsVideo = !!document.createElement('video').canPlayType;

                    if (supportsVideo) {
                        // Obtain handles to main elements
                        var videoContainer = document.getElementById('videoContainer');

                        var videoControls = document.getElementById('video-controls');

                        // Hide the default controls
                        video.controls = false;

                        // Display the user defined video controls
                        videoControls.setAttribute('data-state', 'visible');

                        // Obtain handles to buttons and other elements
                        var playpause = document.getElementById('playpause');
                        var stop = document.getElementById('stop');
                        var mute = document.getElementById('mute');
                        var volinc = document.getElementById('volinc');
                        var voldec = document.getElementById('voldec');
                        var progress = document.getElementById('progress');
                        var progressBar = document.getElementById('progress-bar');
                        var fullscreen = document.getElementById('fs');

                        // If the browser doesn't support the progress element, set its state for some different styling
                        var supportsProgress = (document.createElement('progress').max !== undefined);
                        if (!supportsProgress) progress.setAttribute('data-state', 'fake');

                        // Check if the browser supports the Fullscreen API
                        var fullScreenEnabled = !!(document.fullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled || document.webkitSupportsFullscreen || document.webkitFullscreenEnabled || document.createElement('video').webkitRequestFullScreen);
                        // If the browser doesn't support the Fulscreen API then hide the fullscreen button
                        if (!fullScreenEnabled) {
                            fullscreen.style.display = 'none';
                        }

                        // Check the volume
                        var checkVolume = function(dir) {
                            if (dir) {
                                var currentVolume = Math.floor(video.volume * 10) / 10;
                                if (dir === '+') {
                                    if (currentVolume < 1) video.volume += 0.1;
                                }
                                else if (dir === '-') {
                                    if (currentVolume > 0) video.volume -= 0.1;
                                }
                                // If the volume has been turned off, also set it as muted
                                // Note: can only do this with the custom control set as when the 'volumechange' event is raised, there is no way to know if it was via a volume or a mute change
                                if (currentVolume <= 0) video.muted = true;
                                else video.muted = false;
                            }
                            changeButtonState('mute');
                        }

                        // Change the volume
                        var alterVolume = function(dir) {
                            checkVolume(dir);
                        }

                        // Set the video container's fullscreen state
                        var setFullscreenData = function(state) {
                            videoContainer.setAttribute('data-fullscreen', !!state);
                            // Set the fullscreen button's 'data-state' which allows the correct button image to be set via CSS
                            fullscreen.setAttribute('data-state', !!state ? 'cancel-fullscreen' : 'go-fullscreen');
                        }

                        // Checks if the document is currently in fullscreen mode
                        var isFullScreen = function() {
                            return !!(document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement);
                        }

                        // Fullscreen
                        var handleFullscreen = function() {
                            // If fullscreen mode is active...
                            if (isFullScreen()) {
                                // ...exit fullscreen mode
                                // (Note: this can only be called on document)
                                if (document.exitFullscreen) document.exitFullscreen();
                                else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
                                else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
                                else if (document.msExitFullscreen) document.msExitFullscreen();
                                setFullscreenData(false);
                            }
                            else {
                                // ...otherwise enter fullscreen mode
                                // (Note: can be called on document, but here the specific element is used as it will also ensure that the element's children, e.g. the custom controls, go fullscreen also)
                                if (videoContainer.requestFullscreen) videoContainer.requestFullscreen();
                                else if (videoContainer.mozRequestFullScreen) videoContainer.mozRequestFullScreen();
                                else if (videoContainer.webkitRequestFullScreen) {
                                    // Safari 5.1 only allows proper fullscreen on the video element. This also works fine on other WebKit browsers as the following CSS (set in styles.css) hides the default controls that appear again, and
                                    // ensures that our custom controls are visible:
                                    // figure[data-fullscreen=true] video::-webkit-media-controls { display:none !important; }
                                    // figure[data-fullscreen=true] .controls { z-index:2147483647; }
                                    video.webkitRequestFullScreen();
                                }
                                else if (videoContainer.msRequestFullscreen) videoContainer.msRequestFullscreen();
                                setFullscreenData(true);
                            }
                        }

                        // Only add the events if addEventListener is supported (IE8 and less don't support it, but that will use Flash anyway)
                        if (document.addEventListener) {
                            // Wait for the video's meta data to be loaded, then set the progress bar's max value to the duration of the video
                            video.addEventListener('loadedmetadata', function() {
                                progress.setAttribute('max', video.duration);
                            });

                            // Changes the button state of certain button's so the correct visuals can be displayed with CSS
                            var changeButtonState = function(type) {
                                // Play/Pause button
                                if (type == 'playpause') {
                                    if (video.paused || video.ended) {
                                        playpause.setAttribute('data-state', 'play');
                                    }
                                    else {
                                        playpause.setAttribute('data-state', 'pause');
                                    }
                                }
                                // Mute button
                                else if (type == 'mute') {
                                    mute.setAttribute('data-state', video.muted ? 'unmute' : 'mute');
                                }
                            }

                            // Add event listeners for video specific events
                            video.addEventListener('play', function() {
                                changeButtonState('playpause');
                            }, false);
                            video.addEventListener('pause', function() {
                                changeButtonState('playpause');
                            }, false);
                            video.addEventListener('volumechange', function() {
                                checkVolume();
                            }, false);

                            // Add events for all buttons
                            playpause.addEventListener('click', function(e) {
                                if (video.paused || video.ended) video.play();
                                else video.pause();
                            });

                            // The Media API has no 'stop()' function, so pause the video and reset its time and the progress bar
                            stop.addEventListener('click', function(e) {
                                video.pause();
                                video.currentTime = 0;
                                progress.value = 0;
                                // Update the play/pause button's 'data-state' which allows the correct button image to be set via CSS
                                changeButtonState('playpause');
                            });
                            mute.addEventListener('click', function(e) {
                                video.muted = !video.muted;
                                changeButtonState('mute');
                            });
                            volinc.addEventListener('click', function(e) {
                                alterVolume('+');
                            });
                            voldec.addEventListener('click', function(e) {
                                alterVolume('-');
                            });
                            fs.addEventListener('click', function(e) {
                                handleFullscreen();
                            });

                            // As the video is playing, update the progress bar
                            video.addEventListener('timeupdate', function() {
                                // For mobile browsers, ensure that the progress element's max attribute is set
                                if (!progress.getAttribute('max')) progress.setAttribute('max', video.duration);
                                progress.value = video.currentTime;
                                progressBar.style.width = Math.floor((video.currentTime / video.duration) * 100) + '%';
                            });

                            // React to the user clicking within the progress bar
                            progress.addEventListener('click', function(e) {
                                //var pos = (e.pageX  - this.offsetLeft) / this.offsetWidth; // Also need to take the parent into account here as .controls now has position:relative
                                var pos = (e.pageX  - (this.offsetLeft + this.offsetParent.offsetLeft)) / this.offsetWidth;
                                video.currentTime = pos * video.duration;
                            });

                            // Listen for fullscreen change events (from other controls, e.g. right clicking on the video itself)
                            document.addEventListener('fullscreenchange', function(e) {
                                setFullscreenData(!!(document.fullScreen || document.fullscreenElement));
                            });
                            document.addEventListener('webkitfullscreenchange', function() {
                                setFullscreenData(!!document.webkitIsFullScreen);
                            });
                            document.addEventListener('mozfullscreenchange', function() {
                                setFullscreenData(!!document.mozFullScreen);
                            });
                            document.addEventListener('msfullscreenchange', function() {
                                setFullscreenData(!!document.msFullscreenElement);
                            });
                        }
                    }
                },
                handleMenuClick: function () {
                   $('.menu-item-has-children').on('click', function (e) {
                       var isOpen = $(this).hasClass('open');
                       $('.menu-item-has-children').removeClass('open');

                           if (!isOpen ) {
                               e.preventDefault();
                               $(this).addClass('open');
                           }
                   });
                },
                setMenuSticky: function() {
                    var $home = $('.home');
                        if(!$home.length) {
                            $(window).scroll(function () {
                                var scrollTop = $(window).scrollTop();
                                var height = $(window).height();
                                if (scrollTop > 90) {
                                    $("#menu-header").addClass('stickynh');
                                } else {
                                    $("#menu-header").removeClass('stickynh');
                                }
                            });
                        };
                },
                capitalizeFirstLetter  : function (string) {
                    return string.charAt(0).toUpperCase() + string.slice(1);
                },
                slowConnection : function () {
                  return  $('body').hasClass('slowconnection');
                },
                dealerlocator: function () {


                },
                isScrolledIntoView: function(elem)
                {
                    $(".lazy").lazyload({
                        effect : "fadeIn"
                    });
                    var docViewTop = $(window).scrollTop();
                    var docViewBottom = docViewTop + $(window).height();

                    var elemTop = elem.offset().top;
                    var elemBottom = elemTop + elem.height();
                    console.log(elemBottom);
                    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
                },
                scooterSlide: function ($frame) {


                    var $wrap = $($frame).parent();

                    var frame = new Sly($frame, {
                        horizontal: 1,
                        itemNav: 'basic',
                        smart: 1,
                        activateMiddle: 0,
                        mouseDragging: 0,
                        touchDragging: 1,
                        releaseSwing: 1,

                        speed: 300,
                        elasticBounds: 0,
                        easing: 'swing',
                        dragHandle: 1,
                        dynamicHandle: 1,
                        clickBar: 0,
                        syncSpeed:     0.5,
                        cycleBy:       'pages',  // Enable automatic cycling by 'items' or 'pages'.
                        cycleInterval: 3000,  // Delay between cycles in milliseconds.
                        pauseOnHover:  true, // Pause cycling when mouse hovers over the FRAME.

                        // Buttons
                        prevPage: $wrap.find('.scooter-left'),
                        nextPage: $wrap.find('.scooter-right')

                    });

                    frame.init();
                },
                setBackground: function () {
                    /* BG Srcset 1.0 */
                    (function(){
                        'use strict';

                        var bgsrcset = function(){

                            this.called   = false;
                            this.callonce = true;
                            this.compat();
                        };

                        bgsrcset.prototype.init = function(target, callback){
                            //retina bool
                            this.retina = window.devicePixelRatio > 1;

                            //storage for our elements
                            this.elements = [];

                            //global onload callback for imagery
                            this.callback = typeof callback === 'function' ? callback : function(){};

                            //window width, for responsive handling
                            this.curwidth = this.getWidth();

                            //get our input and turn it into an element list of some sort
                            var elems = this.gather(target);

                            //parse the element input
                            for(var i = 0, l = elems.length; i < l; i++){ this.parse(elems[i]); }

                            this.set();
                            this.resize();
                        };

                        /* -----------* /
                         Fix compatibility issues*
                         *only down to IE8
                         / *----------- */

                        bgsrcset.prototype.compat = function(){
                            var d = document;
                            /* check for getElementsByClassName */
                            if(typeof d.getElementsByClassName !== 'function'){
                                d.getElementsByClassName = function(str){
                                    return d.querySelectorAll('.' + str);
                                };
                            }

                            /* check for .trim() */
                            if (!String.prototype.trim) {
                                String.prototype.trim=function(){return this.replace(/^\s+|\s+$/g, '');};
                            }

                            /*------------------------* /
                             Check for Event Listener
                             / *------*/
                            if(!d.addEventListener){
                                this.addEvent = function(elem, evName, fn){
                                    elem.attachEvent('on'+evName,function(e) {
                                        fn(e || window.event);
                                    });
                                };
                            }

                        };

                        /* -----------* /
                         Gather elements
                         / *----------- */
                        bgsrcset.prototype.gather = function(target){
                            var autotypes = ['HTMLCollection', 'NodeList'];
                            var e = target;
                            var type =  (e.nodeType) ? 'Object' : Object.prototype.toString.call( e ).replace(/^\[object |\]$/g, '');

                            var func = 'parse' + type;

                            if(autotypes.indexOf(type) > -1){
                                return e;
                            }

                            if( this[func] ){
                                return this[func](e);
                            }

                            return [];
                        };

                        bgsrcset.prototype.parseObject = function( target ) {
                            return (target.nodeType) ? [target] : [];
                        };

                        bgsrcset.prototype.parseArray = function( target ) {
                            return target;
                        };

                        bgsrcset.prototype.parseString = function( target ) {

                            var d = document;
                            var e = target.trim();
                            var sel = e[0];
                            var elems = [];

                            switch(true){
                                /* class */
                                case sel === '.':
                                    elems = d.getElementsByClassName(e.substring(1));
                                    break;
                                /* id */
                                case sel === '#':
                                    elems.push(d.getElementById(e.substring(1)));
                                    break;
                                /* tag */
                                case /^[a-zA-Z]+$/.test(e):
                                    elems = d.getElementsByTagName(e);
                                    break;
                                /* unknown */
                                default:
                                    elems = [];
                            }

                            return elems;
                        };

                        /* -----------* /
                         Parse datasrc
                         / *----------- */
                        bgsrcset.prototype.parse = function(obj){
                            //our data to parase
                            var bgss = obj.getAttribute('bg-srcset');
                            /* exit if no attribute */
                            if(attr === null){ return false; }

                            /* create new element object */
                            this.elements.push({});

                            /* split up sets */
                            var set = bgss.split(',');
                            var attr = '';
                            var curelem = this.elements[this.elements.length - 1];


                            curelem.node = obj;
                            curelem.srcset = [];

                            /* loop through sets to define breakpoints */
                            for(var i = 0, l = set.length; i < l; i++){
                                curelem.srcset.push({});
                                attr = set[i].trim();
                                var attrs = attr.split(' ');
                                var a;
                                var e;
                                var t;
                                /* since we can't know the order of the values, starting another loop */
                                for(var attrc = 0, attrl = attrs.length; attrc < attrl; attrc++){
                                    a = attrs[attrc];
                                    e = curelem.srcset[i]; //current attribute
                                    t = a.length-1;
                                    switch(true){
                                        case a.trim() === "":
                                            //in case of extra white spaces
                                            continue;
                                        case a[t] !== 'w' && a[a.length-1] !== 'x':
                                            e.src = a;
                                            break;
                                        case a[t] === 'w':
                                            e.width = parseInt( a.slice( 0, -1 ) );
                                            break;
                                        case a[t] === 'x':
                                            e.retina = ( parseInt( a.slice( 0, -1 ) ) > 1);
                                            break;
                                    }
                                    if(! e.width ){ e.width = Number.POSITIVE_INFINITY; } //set to the top
                                    if(! e.retina ){ e.retina = false; }
                                }
                            }
                        };

                        /* -----------* /
                         Set image
                         / *----------- */
                        bgsrcset.prototype.set = function(){
                            for(var i = 0, l = this.elements.length; i < l; i++){
                                this.setSingle(i);
                            }
                        };

                        bgsrcset.prototype.setSingle = function(id){
                            var width = 0,
                                elem = this.elements[id],
                                comparray = [],
                                best = 0,
                                _this = this;

                            width = this.getWidth(); //elem.node.offsetWidth;

                            elem.srcset = elem.srcset.sort(dynamicSort("width"));

                            for(var i = 0, l = elem.srcset.length; i < l; i++){
                                if(elem.srcset[i].width < width){
                                    continue;
                                }
                                comparray.push( elem.srcset[i] );
                            }
                            if(comparray.length === 0){
                                comparray.push(elem.srcset[elem.srcset.length -1]);
                            }

                            best = comparray[0];

                            if(comparray.length > 1 && comparray[0].width === comparray[1].width){
                                best = (comparray[0].retina !== this.retina) ? comparray[1] : comparray[0];
                            }

                            if( best.src !== undefined && best.src !== 'null'){
                                var img = new Image();
                                var done = false;

                                img.onload = img.onreadystatechange = function() {
                                    if ( !done && (!this.readyState ||
                                        this.readyState === "loaded" || this.readyState === "complete") ) {
                                        done = true;

                                        elem.node.style.backgroundImage = "url('" + best.src + "')";

                                        /* only fire the callback on initial load, not resize events */
                                        if (!_this.called) {

                                            _this.callback(elem);
                                            _this.called = _this.callonce;

                                        }

                                    }

                                };

                                img.src = best.src;
                            }else{
                                elem.node.style.backgroundImage = "";
                            }




                        };

                        /* -----------* /
                         Handle Resize
                         / *----------- */
                        bgsrcset.prototype.resize = function(){
                            var _this = this,
                                resizeTimer = setTimeout(function(){}, 0);

                            this.addEvent(window, 'resize',function() {
                                clearTimeout(resizeTimer);
                                resizeTimer = setTimeout(function(){
                                    var w =  _this.getWidth();
                                    if ( w !== _this.curwidth ){
                                        _this.curwidth = w;
                                        _this.set();
                                    }
                                }, 250);
                            });
                        };

                        bgsrcset.prototype.addEvent = function(elem, evName, fn){
                            elem.addEventListener(evName,fn,false);
                        };

                        bgsrcset.prototype.getWidth = function(){
                            var w, d, e, g;
                            w = window;
                            d = document;
                            e = d.documentElement;
                            g = d.getElementsByTagName('body')[0];

                            return w.innerWidth || e.clientWidth || g.clientWidth;
                        };

                        function dynamicSort(property) {
                            var sortOrder = 1;
                            if(property[0] === "-") {
                                sortOrder = -1;
                                property = property.substr(1);
                            }
                            return function (a,b) {
                                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                                return result * sortOrder;
                            };
                        }

                        window.bgsrcset = bgsrcset;
                    })();
                    /*
                     usage
                     var bgss = new bgsrcset();
                     bgss.init('.bgimg' );
                     */
                    var bgss = new bgsrcset();

                    bgss.callonce = false;

                    bgss.init('.bgimg', function(a){
                        a.node.className += ' loaded';
                    } );

                },
                startScooterCarousel: function () {
                    $('.scooter-carousel').carousel({
                        interval: 3000
                    });
                },
                checkIfIOS: function () {
                    if(window.innerHeight > window.innerWidth){
                        $('body').addClass('vp-portrait');
                    }else{
                        $('body').addClass('vp-landscape');
                    }
                    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
                         $('body').addClass('ios-device');
                    }
                }
            }
        }();
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {



          LAMBRETTA.setBackground();
          LAMBRETTA.checkIfIOS();
          var $beforeimg = $('.before'),
              img_width = $('.before img').width(),
              init_split = Math.round(img_width / 2);

          $beforeimg.width(init_split);

          $('.before_after_slider').mousemove(function (e) {
              var offX = (e.offsetX || e.clientX - $beforeimg.offset().left);
              $beforeimg.width(offX);
          });

          $('a.thumb').click(function(event){
              console.log('click');
              event.preventDefault();
              var content = $('.modal-body');
              content.empty();
              var title = $(this).attr("title");
              $('.modal-title').html(title);
              var $img = $('<img/>').attr('src', $(this).attr('rel'));
              content.html($img);
              $(".modal-profile").modal({show:true});
          });

          $('.parral-div-holder').click(function (e) {
              var pWidth = $(this).innerWidth(); //use .outerWidth() if you want borders
              var pOffset = $(this).offset();
              var x = e.pageX - pOffset.left;
              if(pWidth/2 > x)
                $(this).find('.carousel').carousel('prev');
              else
                  $(this).find('.carousel').carousel('next');
          });

          LAMBRETTA.setParral();
          $(window).scroll(function () {
              var scrollTop = $(window).scrollTop();
              var height = $(window).height();

              LAMBRETTA.setParral();


              $(".full-video-holder").each(function() {

                  var isElementInView = Utils.isElementInView($(this), false);

                  if (isElementInView) {
                      console.log('in view');
                      $(this).find('video').show();
                  } else {
                      console.log('out view');
                      $(this).find('video').hide();
                  }

              });
          });
          LAMBRETTA.startScooterCarousel();

          if($('.firstgallery').length) {
              LAMBRETTA.setGallery();
          }

          $('.vid_trigger').click( function(e){
              e.preventDefault();
              var URL = $(this).attr('href');
              var htm = '<iframe width="100%" src="//www.youtube.com/embed/' + URL + '?rel=0&autoplay=1" frameborder="0" allowfullscreen ></iframe>';

              $(this).html(htm);

              return false;
          });

          $('.web_trigger').click( function(e){
              e.preventDefault();
              var height = $(this).find('img').height();


              var figure = $('<figure/>',{
                  id : "videoContainer",
                  width : '100%',
                  height  : height + 'px'
              });

              var URL = $(this).attr('href');
              var video = $('<video/>', {
                  controls : '',
                  autoplay : '',
                  height: '100%'

              }).appendTo(figure);
              var mp4 = $('<source/>', {
                  src : URL,
                  type : 'video/mp4',
                  text : ' Your browser does not support HTML5 video.'
              }).appendTo(video);

              var div = $('<div/>', {
                  id            : "video-controls",
                  'class'       : 'controls',
                  'data-state'  : 'hidden'
              }).appendTo(figure);
             /* $('<button/>', {
                  id : "playpause",
                  type : "button",
                  'data-state' : "play",
                  html : 'Play/Pause'
              }).appendTo(div);*/

              /*<div id="video-controls" class="controls" data-state="hidden">
               <button id="playpause" type="button" data-state="play">Play/Pause</button>
               <button id="stop" type="button" data-state="stop">Stop</button>
               <div class="progress">
               <progress id="progress" value="0" min="0">
               <span id="progress-bar"></span>
               </progress>
               </div>
               <button id="mute" type="button" data-state="mute">Mute/Unmute</button>
               <button id="volinc" type="button" data-state="volup">Vol+</button>
               <button id="voldec" type="button" data-state="voldown">Vol-</button>
               <button id="fs" type="button" data-state="go-fullscreen">Fullscreen</button>
               </div>*/


              $(this).parent().html(figure);
             // LAMBRETTA.handleVideo(video);
              return false;
          });

         /*$(window).scroll(function () {
              var isElementInView = Utils.isElementInView($('.nav-primary'), false);
              if (isElementInView) {
                  $('.nav-primary-home').removeClass('stickynh');
              } else {
                  $('.nav-primary-home').addClass('stickynh');
              }

          });*/
          if ($(window).width() < 768) {


              LAMBRETTA.handleMenuClick();
          }else{
              LAMBRETTA.setMenuSticky();
          }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

          var mainWidth = $('.main').width();

          $('.fitScreen').each(function () {
             $(this).css('background-size', mainWidth);
          });
          $('.fullParallaxFoto').each(function () {

              var newImg =  $(this).data('lg');
              console.log(newImg);
              $(this).css("background-image", "url(http://localhost/lambretta-2018/wp-content/uploads/2017/06/MTB-Assiano.jpg);");
          });


              jQuery('.fullParallaxFoto').Lazy();
            jQuery('.slowLoad').Lazy();
          jQuery('.sliderload').Lazy();


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
          // JavaScript to be fired on all pages
          setTimeout(function(){
              $('body').addClass('loaded');
              $('h1').css('color','#222222');
          }, 1000);

          $(window).scroll(function () {
              var isElementInView = Utils.isElementInView($('header'), false);
                  if (isElementInView) {

                      $(this).find('.setparral').addClass('parral-div');
                      $(this).find('video').show();

                      $('.nav-primary-home').removeClass('sticky');
                  } else {
                      $(this).find('.setparral').removeClass('parral-div');
                      $(this).find('video').hide();
                      $('.nav-primary-home').addClass('sticky');
                  }

          });

          var slowLoad = window.setTimeout( function() {
              $('body').addClass('slowconnection');
              $("#scooterslider").remove();
              $("#treewheelslider").remove();
          }, 2000 );

          window.addEventListener( 'load', function() {
              window.clearTimeout( slowLoad );
              var bgv = $('#bgvid');
              if (bgv.is(':visible')) {
                  $('source', bgv).each(
                      function () {
                          var el = $(this);
                          el.attr('src', el.data('src'));
                      }
                  );

                  bgv[0].load();
              }
              LAMBRETTA.scooterSlide("#scooterslider");
              LAMBRETTA.scooterSlide("#treewheelslider");

          }, false );

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
      'history':{
          init: function() {
            /*  $('iframe').on('load', function () {
                $('.bubblingG').show();
              });
              $('iframe').ready(function(){
                  $('.bubblingG').hide();
              });
*/
          }
      },
      'dealer_locator': {
          init: function() {
              $(function initMap() {
                  DEALERLOCATOR.initMap();
              });
          },
          finalize: function() {
           //  DEALERLOCATOR.initMap();
          }
      }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
